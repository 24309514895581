body {
	--color_black: rgb(0, 0, 0);
	--color_pink: rgb(255, 42.5, 85);
	--color_blue: rgb(42.5, 200, 255);
	--color_red: rgb(255, 0, 0);
	--color_background: hsl(0, 0%, 13%);
	--color_navigation: rgba(0, 0, 0, 0.85);
	--color_navigation_active: rgba(20, 20, 20, 0.85);
	--color_input: rgba(20, 20, 20, 0.5);

	--font_size_small: 12px;
	--font_size_medium: 16px;
	--font_size_large: 24px;
	--font_size_huge: 32px;

	background-color: var(--color_background);
	color: var(--color_blue);
	margin: 0;
	padding: 0;

	font-family: 'Ubuntu', sans-serif;
	font-size: var(--font_size_medium);

	display: flex;
	flex-direction: column;
}

h1 {
	margin: calc(var(--font_size_huge) / 2);
	padding: 0;
	color: var(--color_blue);
	font-size: var(--font_size_huge);
	text-align: center;
}

.box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 90%;
	margin: 5vh auto auto auto;
	padding: 0;

	color: var(--color_blue);
}

.box_login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: clamp(20%, 400px, 80%);

	background-color: var(--color_navigation);
	color: var(--color_blue);
	margin: 30vh auto auto auto;
	padding: 0;
	border-radius: 1em;

	box-shadow: 0 0 10px var(--color_pink);
	overflow: hidden;
}

.box_login.error_login {
	box-shadow: 0 0 10px var(--color_red);
}

.box_login input {
	width: calc(100% - var(--font_size_medium));
	margin: 0;
	padding: calc(var(--font_size_medium) / 2);
	border: none;
	border-radius: 0;
	background-color: var(--color_input);
	color: var(--color_blue);
	font-size: var(--font_size_medium);

	::placeholder {
		color: var(--color_blue);
	}
}

.box_login button {
	width: 100%;
	margin: 0;
	padding: calc(var(--font_size_medium) / 2);
	border: none;
	border-radius: 0;
	background-color: var(--color_blue);
	color: var(--color_black);
	font-size: var(--font_size_medium);
	cursor: pointer;
	overflow: hidden;
}

p.error_login {
	margin: calc(var(--font_size_medium) / 2);
	padding: 0;
	color: var(--color_red);
	font-size: var(--font_size_medium);
	text-align: center;
}

table.leaderboard {
	width: 100%;
	margin: var(--font_size_medium) auto auto auto;
	padding: 0;

	background-color: var(--color_navigation);
	box-shadow: 0 0 10px var(--color_pink);
	overflow: hidden;

	border-radius: 1em;
	border-collapse: collapse;
}

table.leaderboard th {
	height: calc(var(--font_size_medium) * 2);
}

table.leaderboard th,
table.leaderboard td {
	text-align: center;
	padding: calc(var(--font_size_medium) / 2);
}
