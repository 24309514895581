/* src/css/nav.css */

.nav {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 75px;
	padding: 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: var(--color_navigation);
	z-index: 1000;
}

.nav a {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: 13px;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
	transition: background-color 0.1s ease-in-out;
}

.nav a svg {
	width: 20px;
	height: 20px;
	padding: 0 0 10px 0;
	fill: var(--color_blue);
	transition: fill 0.1s ease-in-out;
}

.nav a.active {
	background-color: var(--color_navigation_active);
	transition: background-color 0.1s ease-in-out;
}

.nav a.active svg {
	fill: var(--color_pink);
	/* langsamer übergang in diese farbe */
	transition: fill 0.1s ease-in-out;
}
